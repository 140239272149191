.bg-hero {
  z-index: 10;
  background: no-repeat;
  padding: 250px 0px;
  border-radius: 0px 0px 78px 0px;
  background: linear-gradient(
      to left,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 1) 100%
    ),
    url("../../assets/images/hero2.png"), no-repeat;
}

@media screen and (max-width: 550px) {
  .bg-hero {
    padding: 150px 0px;
  }
}
